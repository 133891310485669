exports.ipField = [
  value => !!value || 'Obrigatório.',
  value => (value || '').length <= 15 || 'Max 15 carateres.',
  value => {
    const pattern = /^((1?\d{1,2}|2([0-4]\d|5[0-5]))\.){3}(1?\d{1,2}|2([0-4]\d|5[0-5]))$|^$/
    return pattern.test(value) || 'Ip invalido.'
  },
];

exports.urlField = [
  value => !!value || 'Obrigatório.',
  value => {
    try {
      new URL(value)
      return true
    } catch (error) {
      return "Url invalida."
    }
  },
];

exports.portField = [
  value => !!value || 'Obrigatório.',
  value => (value || '').length <= 5 || 'Max 5 números.',
  value => (value || '').length > 1 || 'Min 2 números.',
  value => {
    const pattern = /^[0-9]+$/
    return pattern.test(value) || 'Porta invalida.'
  },
];

exports.requiredField = [
  value => !!value || 'Obrigatório.',
];

exports.requiredFieldAutoComplete = [
  value => (Array.isArray(value) && value.length) > 0  || 'Obrigatório.',
];

exports.reqFieldMinLength = (len) => [
  value => !!value || 'Obrigatório.',
  value => (value || '').length >= len || `Min ${len} carateres.`,
];
// valida input de numero com minimo e maximo
exports.reqFieldNumberMinMax = (min, max) => [
  value => {
    let n = parseInt(value)
    return n >= min && n <= max || `Número tem de ser entre ${min} e ${max}.`
  },
  value => `${value}`.length > 0 || 'Obrigatório.',
];

exports.portFieldNotRequired = [
  value => {
    const pattern = /^[0-9]+$/
    return pattern.test(value) || 'Porta invalida.'
  },
];