<template>
  <v-dialog v-bind:value="value" persistent max-width="500">
    <v-card class="grey darken-3">
      <v-card-title>
        <!-- titulo -->
        <span>Executar ação</span>
        <v-spacer></v-spacer>
        <!-- botão fechar -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Fechar</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <div v-if="action.length">
          <v-row
            align-content="center"
            justify="center"
            v-for="(act, idx) in action"
            :key="idx + 'v'"
          >
            <v-btn @click="runAction(act)" class="ma-2">
              <v-icon class="mr-2">{{ act.icon }}</v-icon>
              -
              {{ act.name }}
            </v-btn>
          </v-row>
        </div>
        <div v-else>
          <v-alert type="info" elevation="2" outlined>
            Nenhuma ação cadastrada
          </v-alert>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogAction",
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Array,
    },
  },
  data: () => ({}),

  methods: {
    runAction(data) {
      this.$store.dispatch("runAction", data);
    },
    close() {
      this.$emit("input", false);
    },
  },
  // watch: {
  //   value: function (value) {
  //     if (value) {

  //     }
  //   },
  // },
};
</script>

<style>
.height-100 {
  height: 100%;
}
</style>