<template>
  <v-card id="card12345" class="over">
    <v-expansion-panels :disabled="!getEvento.id">
      <v-expansion-panel>
        <v-expansion-panel-header color="primary" dark v-if="getEvento.id">
          <span class="font-weight-bold">Evento:</span>
          <span>
            {{ getEvento.id }} -
            {{ getEvento.evtName || `Não Cadastrado ${getEvento.code}` }}
          </span>
          <span class="font-weight-bold">Câmera:</span>
          <span>{{ getEvento.cameraId }} - {{ getEvento.cameraName }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-header color="primary" dark v-else>
          Selecione um Evento
        </v-expansion-panel-header>
        <v-expansion-panel-content v-if="getEvento.id">
          <v-row>
            <v-col></v-col>
          </v-row>
          <v-row>
            <v-col> Cliente: {{ getEvento.clientName }} </v-col>
            <v-col>
              Data do Evento:
              {{ new Date(getEvento.createdAt).toLocaleString() }}
            </v-col>
          </v-row>
          <!-- linha nome, zona, canal, baixar imagens -->
          <v-row>
            <v-col cols="6">
              Câmera: {{ getEvento.cameraId }} - {{ getEvento.cameraName }}
            </v-col>
            <v-col> Zona: {{ getEvento.zone }} </v-col>
            <v-col> Canal: {{ getEvento.channel }} </v-col>
            <v-col cols="auto">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="getImgFromCanvas" v-bind="attrs" v-on="on" icon
                    ><v-icon>mdi-draw</v-icon></v-btn
                  >
                </template>
                <span>Baixar imagem com desenho</span>
              </v-tooltip>
            </v-col>
            <v-col cols="auto">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="getImgFromServer" v-bind="attrs" v-on="on" icon
                    ><v-icon>mdi-camera-image</v-icon></v-btn
                  >
                </template>
                <span>Baixar imagem original</span>
              </v-tooltip>
            </v-col>
            <v-col cols="auto">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="getActions" v-bind="attrs" v-on="on" icon
                    ><v-icon>mdi-console-network-outline</v-icon></v-btn
                  >
                </template>
                <span>Ações da câmera</span>
              </v-tooltip>
            </v-col>
            <v-col cols="auto">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="snooze" v-bind="attrs" v-on="on" icon
                    ><v-icon>mdi-alarm-snooze</v-icon></v-btn
                  >
                </template>
                <span>Pausar camera</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <canvas
      id="image"
      :width="canvasSize.width"
      :height="canvasSize.height"
    ></canvas>
    <!-- botoes anterior e proximo -->
    <v-btn
      text
      icon
      class="btnleft"
      color="primary"
      @click="$emit('previous', getEvento.id)"
      v-show="getEvento.id"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <v-btn
      text
      icon
      class="btnright"
      color="primary"
      @click="$emit('next', getEvento.id)"
      v-show="getEvento.id"
    >
      <v-icon>mdi-arrow-right</v-icon>
    </v-btn>
    <DialogAction v-model="dialogActions" :action="getCameraActions" />
    <DialogSnooze v-model="dialogSnooze" :cameras="cameras" />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import FileUtil from "@/Utils/File";
import CanvasDraw from "@/Utils/CanvasDraw";
import DialogAction from "./DialogAction.vue";
import DialogSnooze from "./DialogSnooze.vue";
export default {
  name: "Atendimento",
  data: () => {
    return {
      event: {},
      canvasSize: { width: 0, height: 0 },
      canvas: null,
      context: null,
      proportion: { width: 0, height: 0 },
      image: { width: 1920, height: 1080 },
      drawLimits: {
        width: {
          values: [],
          max: 0,
          min: 0,
        },
        height: {
          values: [],
          max: 0,
          min: 0,
        },
      },
      dialogActions: false,
      dialogSnooze: false,
      cameras:[]
    };
  },
  components: {
    DialogAction,
    DialogSnooze
  },
  computed: {
    ...mapGetters([
      "getEvento",
      "getTratamento",
      "getCameraActions",
      "getEventos",
    ]),
  },
  methods: {
    selEvt(event) {
      this.event = event;
    },
    showEvent() {
      // pegando o canvas
      let canvas = document.getElementById("image");
      let ctx = canvas.getContext("2d");
      // deixando ele do tamanho da imagem
      canvas.width = this.canvasSize.width;
      canvas.height = this.canvasSize.height;
      // carregando a imagem e desenhando

      CanvasDraw.drawImage({ ...this.getEvento }, this.canvasSize)
        .then((dataUri) => {
          // colocando ela no image do HTML
          let image = new Image();
          image.onload = () => {
            // depois de carregada a imagem, desenhamos ela no canvas
            ctx.drawImage(
              image,
              0,
              0,
              this.canvasSize.width,
              this.canvasSize.height
            );
          };
          image.src = dataUri;
        })
        .catch(() => {
          this.noImg();
        });
    },
    clearScreen() {
      let canvas = document.getElementById("image");
      let ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      // largura da linha
      ctx.lineWidth = 2;
      // ctx.fillStyle = "rgba(0, 127, 127, 1)";
      ctx.strokeStyle = "rgba(255, 165, 0, 1)";
      ctx.textAlign = "center";
      ctx.font = "30px Arial";
      ctx.strokeText(
        `Selecione um Evento`.toLocaleUpperCase(),
        canvas.width / 2,
        canvas.height / 2
      );
    },
    noImg() {
      let canvas = document.getElementById("image");
      let ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      // largura da linha
      ctx.lineWidth = 2;
      // ctx.fillStyle = "rgba(0, 127, 127, 1)";
      ctx.strokeStyle = "rgba(255, 165, 0, 1)";
      ctx.textAlign = "center";
      ctx.font = "30px Arial";
      ctx.strokeText(
        `Sem Imagem`.toLocaleUpperCase(),
        canvas.width / 2,
        canvas.height / 2
      );
    },
    getImgFromCanvas() {
      // desenhar imagem no canvas
      CanvasDraw.drawImage({ ...this.getEvento }).then((dataUri) => {
        // converter para blob
        let blob = FileUtil.dataURItoBlob(dataUri);
        // nome do arquivo
        let filename = `${this.getEvento.id}-${this.getEvento.evtName}-drawed.${
          blob.type.split("/")[1]
        }`;
        // baixar arquivo
        FileUtil.downloadFile(blob, filename);
      });
    },
    getImgFromServer() {
      // url para download da imagem
      let url = this.getEvento.snapshot + this.$store.getters.getImgTokenQuery;
      //  baixando a imagem do servidor
      fetch(url)
        .then((response) => response.blob())
        .then((myBlob) => {
          let filename = `${this.getEvento.id}-${this.getEvento.evtName}.${
            myBlob.type.split("/")[1]
          }`;
          // baixar arquivo
          FileUtil.downloadFile(myBlob, filename);
        })
        .catch(() => {
          // console.log(error);
        });
    },
    getActions() {
      this.$store.dispatch("loadCameraActions", {
        id: this.getEvento.cameraId,
      });
      this.dialogActions = true;
    },
    snooze() {
      let ids = [];
      let names = [];
      this.getEventos.forEach((evt) => {
        ids.push(evt.cameraId);
        names.push(evt.cameraName);
      });
      ids = [...new Set(ids)];
      names = [...new Set(names)];
      let cameras = ids.map((e, i) => {
        return { value: e, text: names[i] };
      });
      this.cameras = cameras;
      this.dialogSnooze = true;
    },
  },
  watch: {
    getEvento: {
      handler(val) {
        if (val) {
          let divCard = document.getElementById("card12345");
          this.canvasSize.width = divCard.offsetWidth;
          this.canvasSize.height = divCard.offsetHeight;
          this.showEvent(val);
        }
      },
    },
    getTratamento: {
      // limpa a tela ao tratar um evento
      handler(val) {
        if (!val) {
          this.clearScreen();
        }
      },
      deep: true,
    },
  },
  mounted() {
    let divCard = document.getElementById("card12345");
    this.canvasSize.width = divCard.offsetWidth;
    this.canvasSize.height = divCard.offsetHeight;
    this.clearScreen();
  },
};
</script>

<style scoped>
#image {
  width: 100%;
}
.over {
  height: 80vh;
}

.btnright {
  position: absolute;
  top: 50%;
  right: 15px;
}
.btnleft {
  position: absolute;
  top: 50%;
  left: 15px;
}
</style>