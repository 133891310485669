var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"over",attrs:{"id":"card12345"}},[_c('v-expansion-panels',{attrs:{"disabled":!_vm.getEvento.id}},[_c('v-expansion-panel',[(_vm.getEvento.id)?_c('v-expansion-panel-header',{attrs:{"color":"primary","dark":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Evento:")]),_c('span',[_vm._v(" "+_vm._s(_vm.getEvento.id)+" - "+_vm._s(_vm.getEvento.evtName || ("Não Cadastrado " + (_vm.getEvento.code)))+" ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Câmera:")]),_c('span',[_vm._v(_vm._s(_vm.getEvento.cameraId)+" - "+_vm._s(_vm.getEvento.cameraName))])]):_c('v-expansion-panel-header',{attrs:{"color":"primary","dark":""}},[_vm._v(" Selecione um Evento ")]),(_vm.getEvento.id)?_c('v-expansion-panel-content',[_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col',[_vm._v(" Cliente: "+_vm._s(_vm.getEvento.clientName)+" ")]),_c('v-col',[_vm._v(" Data do Evento: "+_vm._s(new Date(_vm.getEvento.createdAt).toLocaleString())+" ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Câmera: "+_vm._s(_vm.getEvento.cameraId)+" - "+_vm._s(_vm.getEvento.cameraName)+" ")]),_c('v-col',[_vm._v(" Zona: "+_vm._s(_vm.getEvento.zone)+" ")]),_c('v-col',[_vm._v(" Canal: "+_vm._s(_vm.getEvento.channel)+" ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.getImgFromCanvas}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-draw")])],1)]}}],null,false,3549894379)},[_c('span',[_vm._v("Baixar imagem com desenho")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.getImgFromServer}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-camera-image")])],1)]}}],null,false,2828842549)},[_c('span',[_vm._v("Baixar imagem original")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.getActions}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-console-network-outline")])],1)]}}],null,false,2067099346)},[_c('span',[_vm._v("Ações da câmera")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.snooze}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-alarm-snooze")])],1)]}}],null,false,1096866270)},[_c('span',[_vm._v("Pausar camera")])])],1)],1)],1):_vm._e()],1)],1),_c('canvas',{attrs:{"id":"image","width":_vm.canvasSize.width,"height":_vm.canvasSize.height}}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.getEvento.id),expression:"getEvento.id"}],staticClass:"btnleft",attrs:{"text":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('previous', _vm.getEvento.id)}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.getEvento.id),expression:"getEvento.id"}],staticClass:"btnright",attrs:{"text":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('next', _vm.getEvento.id)}}},[_c('v-icon',[_vm._v("mdi-arrow-right")])],1),_c('DialogAction',{attrs:{"action":_vm.getCameraActions},model:{value:(_vm.dialogActions),callback:function ($$v) {_vm.dialogActions=$$v},expression:"dialogActions"}}),_c('DialogSnooze',{attrs:{"cameras":_vm.cameras},model:{value:(_vm.dialogSnooze),callback:function ($$v) {_vm.dialogSnooze=$$v},expression:"dialogSnooze"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }