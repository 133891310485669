<template>
  <v-dialog v-bind:value="value" persistent max-width="500">
    <v-card class="grey darken-3">
      <v-card-title>
        <!-- titulo -->
        <span>Pausar camera</span>
        <v-spacer></v-spacer>
        <!-- botão fechar -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Fechar</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-form ref="formPause">
          <v-row>
            <v-col>
              <v-autocomplete
                :items="cameras"
                label="Selecione as cameras"
                hint="Selecione as cameras que deseja pausar"
                clearable
                multiple
                chips
                small-chips
                outlined
                deletable-chips
                :rules="requiredFieldAutoComplete"
                v-model="snooze.cameras"
              ></v-autocomplete>

              <v-select
                :items="times"
                outlined
                label="Tempo para a pausa"
                hint="Selecione o tempo que deseja pausar"
                :rules="requiredField"
                v-model="snooze.time"
              ></v-select>

              <v-textarea
                rows="3"
                label="Motivo"
                hint="Digite o motivo para pausar a camera"
                outlined
                :rules="requiredField"
                v-model="snooze.reason"
              ></v-textarea>

              <v-text-field
                label="Usuário"
                hint="Digite seu usuário com permissão para pausar a camera"
                outlined
                :rules="requiredField"
                v-model="snooze.user"
              ></v-text-field>

              <v-text-field
                label="Senha"
                hint="Digite sua senha"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                outlined
                @click:append="showPassword = !showPassword"
                :rules="requiredField"
                v-model="snooze.password"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="warning" @click="save">Pausar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  requiredField,
  reqFieldMinLength,
  requiredFieldAutoComplete,
} from "@/Utils/InputValidation";
export default {
  name: "DialogSnooze",
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    cameras: {
      type: Array,
    },
  },
  data: () => ({
    showPassword: false,
    snooze: {},
    requiredField,
    reqFieldMinLength,
    requiredFieldAutoComplete,
    times: [
      { text: "15 Minutos", value: 15 },
      { text: "30 Minutos", value: 30 },
      { text: "60 Minutos", value: 60 },
    ],
  }),

  methods: {
    close() {
      this.$emit("input", false);
    },
    save() {
      if (!this.$refs.formPause.validate()) {
        return;
      }
      this.$http.post("snooze", this.snooze).then(() => {
        this.$emit("input", false);
        this.snooze = {};
        this.$refs.formPause.reset();
      });
    },
  },
};
</script>

<style>
.height-100 {
  height: 100%;
}
</style>